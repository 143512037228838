<template>
  <div class="error-message">
    <p>An error occurred while loading data.</p>
    <p>Check your internet connection.</p>
  </div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/errorPage.scss';
</style>